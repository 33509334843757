<template>
<span>
    <div v-for="file in Files" :key="file.Name">
        <span v-if="file.FullName">
            <a class="file-ref" :href="file.Name" @click.prevent="downloadItem(file)" target="_blank" download>{{ file.Name }}</a>
        </span>
        <span v-else>
            <span>{{ file.name }}</span>
        </span>
    </div>
</span>
</template>

<script>
import downloadFile from '../../mixins/downloadFile';
export default {
    mixins: [downloadFile],
    props: {
        Files: {
            required: true,
            default () {
                return [];
            }
        },IsDownloadable:{
            required: false,
            default:true,
            type:Boolean,
        }
    }
}
</script>

<style>

</style>
